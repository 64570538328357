<template>
  <div>
    <div
      class="flex"
      :class="`size-${size}`"
    >
      <slot name="before" />
      <div
        v-if="prefix"
        :class="focused ? 'border-primary focused' : ''"
        class="prefix whitespace-nowrap bg-gray-100 text-gray-700 dark:bg-gray-800 dark:text-white dark:border-gray-700"
        :title="prefix"
        data-test="prefix"
      >
        <span
          class="w-full truncate"
        >
          {{ prefix }}
        </span>
      </div>
      <input
        :id="inputId"
        ref="input"
        class="b-input form-input focus:border-primary rounded dark:bg-gray-800 dark:border-gray-700 dark:text-white"
        :class="{
          'rounded-r-none': (suffix && !rtl) || (prefix && rtl),
          'bg-gray-100': disabled,
          'bg-white': !disabled,
          'w-full': block,
          'rounded-l-none': (prefix && !rtl) || (suffix && rtl),
          'border-red-500 dark:border-red-500': errors.length > 0 || hasErrors,
        }"
        :value="value"
        :disabled="disabled"
        :type="type"
        :placeholder="placeholder"
        v-bind="$attrs"
        v-on="listeners"
      >
      <div
        v-if="suffix"
        :class="focused ? 'border-primary focused' : ''"
        class="suffix bg-gray-100 text-gray-700 dark:bg-gray-800 dark:text-white whitespace-nowrap"
        data-test="suffix"
        :title="suffix"
      >
        <span
          class="w-full truncate"
        >
          {{ suffix }}
        </span>
      </div>
      <slot name="after" />
    </div>
    <div class="flex justify-between">
      <ul
        v-if="errors.length > 0"
        data-test="errors"
      >
        <li
          v-for="error in errors"
          :key="error"
          class="text-red-500 text-sm mt-1"
          data-test="error"
        >
          {{ error }}
        </li>
      </ul>
      <div
        v-if="maxlength"
        class="text-gray-700 text-sm ml-auto mt-1 font-light"
      >
        Characters left:
        <span
          :class="[ counter <= 0 ? 'text-red-500' : counter < 11 ? 'text-orange-500' : '' ]"
          data-test="max-length-counter"
        >
          {{ counter }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { SIZES } from './BInput.constants'

function isRtl() {
  if (document) {
    return document.body.hasAttribute('dir')
      && document.body.getAttribute('dir') === 'rtl'
  }
  return false
}

export default {
  name: 'BInput',
  props: {
    /**
     * Whether it has errors or not
     */
    hasErrors: {
      type: Boolean,
      default: false,
    },
    /**
     * Input value
     */
    value: {
      type: [String, Number],
      default: '',
    },
    /**
     * An array of errors
     */
    errors: {
      type: Array,
      default: () => [],
    },
    /**
     * Value to be passed to `<input>`'s type
     */
    type: {
      type: String,
      default: 'text',
    },
    /**
     * Text to be displayed on the left side of the input
     */
    size: {
      type: String,
      default: SIZES.base,
      validator: value => Object.values(SIZES).includes(value),
    },
    /**
     * Text to be displayed on the left side of the input
     */
    suffix: {
      type: String,
      default: '',
    },
    /**
     * Text to be displayed on the right side of the input
     */
    prefix: {
      type: String,
      default: '',
    },
    /**
     * Value to be passed to `<input>`'s disabled
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * It takes the full width if this prop is `true`
     */
    block: {
      type: Boolean,
      default: true,
    },
    /**
     * Displays a counter on the footer and limits input to the value of this prop.
     */
    maxlength: {
      type: Number,
      default: null,
    },
    inputId: {
      type: String,
      default: null,
    },
    customErrors: {
      type: Object,
      default: () => ({}),
    },
    placeholder: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      focused: false,
      counter: 0,
      rtl: false,
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInputChange,
        focusin: this.onInputFocusIn,
        focusout: this.onInputFocusOut,
      }
    },
  },
  async mounted() {
    await this.$nextTick()

    const counter = this.value ? this.value : ''
    this.updateCounter(counter)
    this.rtl = isRtl()
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    updateCounter(value) {
      if (this.maxlength) {
        this.counter = this.maxlength - value.length
      }
    },
    onInputFocusIn() {
      this.focused = true
    },
    onInputFocusOut() {
      this.focused = false
    },
    onInputChange({ target }) {
      if (this.type === 'number') {
        const eventValue = parseFloat(target.value)
        this.$emit('input', eventValue)
        return
      }

      this.updateCounter(target.value)
      this.$emit('input', target.value)
    },
  },
}
</script>

<style scoped>
.b-input {
  text-align: inherit;
}

.size-sm {
  > .b-input,
  > .suffix,
  > .prefix {
    @apply text-sm leading-4;

    padding: calc(0.5rem - 1px) calc(0.5rem - 1px);
  }
}

.size-base {
  > .b-input,
  > .suffix,
  > .prefix {
    @apply text-base leading-6;

    padding: calc(0.5rem - 1px) calc(0.75rem - 1px);
  }
}

.size-lg {
  > .b-input,
  > .suffix,
  > .prefix {
    @apply leading-6;

    padding: calc(0.75rem - 1px) calc(1rem - 1px);
  }
}

.focused {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.prefix {
  @apply flex items-center px-3 border rounded-l border-r-0;

  max-width: 50%;
}

.suffix {
  max-width: 50%;

  @apply flex items-center flex-none;
  @apply px-3;
  @apply border rounded-r border-l-0;
}

body[dir=rtl] {
  .prefix {
    @apply border-r rounded-r border-l-0 rounded-l-none;
  }

  .suffix {
    @apply border-l rounded-l border-r-0 rounded-r-none;
  }
}
</style>
